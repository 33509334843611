import useBackups from '../../core/hooks/useBackups';
import React, { useEffect, useState } from 'react';
import AppLoading from '../AppLoading';
import AppTable from '../AppTable';
import AppEmpty from '../AppEmpty';
import BackupStatus from './BackupStatus';
import FriendlyDateTime from '../../types/FriendlyDateTime';
import ExpandableText from '../ExpandableText';
import numbro from 'numbro';
import BackupService from '../../services/BackupService';
import { Modal, notification, Spin } from 'antd';
import CcxIconInfoCircleTwoTone from '../ccx/icons/CcxIconInfoCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import styles from './Backups.module.less';
import AppConfirmDialog from '../AppConfirmDialog';
import DeploymentsItem from '../../types/DeploymentsItem';
import Restore from '../../types/Restore';
import useBackupRestores from '../../core/hooks/useBackupRestores';
import IncrementalBackups from './IncrementalBackups';
import Backup from '../../types/Backup';
import BackupsModal from './BackupsModal';
import RestoringBackups from './RestoringBackups';

type BackupsListProps = {
    deployment: DeploymentsItem;
};
export default function BackupsList({ deployment }: BackupsListProps) {
    const [currentPage, setCurrentPage] = useState(1);
    const [currentRestore, setCurrentRestore] = useState<Restore>();
    const [restoreObj, setRestoreObj] = useState<Backup>();
    const [openRestore, setOpenRestore] = useState<boolean>(false);

    const {
        backups,
        loading: backupsLoading,
        page,
        pageSize,
        total,
    } = useBackups(deployment.dataStoreUuid, currentPage, 20);

    const { backupRestores, refresh: refreshRestores } = useBackupRestores(
        deployment.dataStoreUuid
    );

    useEffect(() => {
        if (backups?.length > 0 && backupRestores?.length > 0) {
            let restore : Restore = backupRestores[0];
            if (!restore.backupType) {
                const backup = (backups as Backup[]).find(b => b.backupId === restore.backupId);
                if (backup) {
                    restore = new Restore({
                        cmon_backup_id: restore.backupId,
                        progress: restore.progress,
                        status: restore.status,
                        status_text: restore.statusText,
                        created_at: restore.createdAt,
                        started_at: restore.startedAt,
                        updated_at: restore.updatedAt,
                        ended_at: restore.endedAt,
                        backup: {
                            backup_type: backup.backupType,
                            size: backup.size,
                        },
                    });
                }
            }

            setCurrentRestore(restore);
        }
    }, [backupRestores, backups]);
    useEffect(() => {
        const AUTO_REFRESH_INTERVAL = 20000;
        const interval = setInterval(async () => {
            refreshRestores();
        }, AUTO_REFRESH_INTERVAL);

        return () => clearInterval(interval);
    }, []);

    const handleTableChange = async (pagination: any) => {
        setCurrentPage(pagination.current);
    };

    const backupsColumns = [
        {
            title: 'Id',
            dataIndex: 'backupId',
            key: 'backupId',
        },
        {
            title: 'Method',
            dataIndex: 'backupMethod',
            key: 'backupMethod',
        },
        {
            title: 'Type',
            dataIndex: 'backupType',
            key: 'backupType',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text: string, record: any) => {
                return (
                    <BackupStatus backup={record} data-testid="BackupStatus" />
                );
            },
        },
        {
            title: 'Started',
            dataIndex: 'startedAt',
            key: 'startedAt',
            width: 230,
            render: (text: string, record: any) => {
                const a = new FriendlyDateTime({
                    date: record.startedAt,
                });
                return (
                    <ExpandableText
                        collapsedText={a?.getFullDate()}
                        expandedText={a?.getFriendlyDate()}
                    />
                );
            },
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            width: 140,
            render: (text: string, record: any) => {
                const from = new Date(record.startedAt);
                const to = new Date(record.endedAt);
                const diff = to.getTime() - from.getTime();
                const secs = diff / 1000;
                let duration;
                if (secs > 59) {
                    duration = `${(secs / 60).toFixed()} mins`;
                } else {
                    duration = `${secs.toFixed()} secs`;
                }

                return (
                    <ExpandableText
                        collapsedText={duration}
                        expandedText={new FriendlyDateTime({
                            date: record.endedAt,
                        }).getFullDate()}
                    />
                );
            },
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
            width: 120,
            align: 'center',
            render: (text: string, record: any) => {
                return numbro(parseInt(record.size, 10)).format({
                    output: 'byte',
                    base: 'binary',
                    spaceSeparated: true,
                    mantissa: 2,
                });
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 85,
            render: (text: string, record: any) => {
                const onConfirmRestore = () => {
                    if (deployment?.isRedis()) {
                        return new Promise(async (resolve, reject) => {
                            try {
                                const result =
                                    await BackupService.restoreBackup(
                                        deployment.dataStoreUuid,
                                        record?.backupId
                                    );

                                notification.open({
                                    message: 'Restore Backup',
                                    description:
                                        'The backup will be restored soon.',
                                    icon: <CcxIconInfoCircleTwoTone />,
                                });

                                refreshRestores();

                                Modal.destroyAll();

                                resolve(null);
                            } catch (e) {
                                notification.open({
                                    message: 'Restore Backup',
                                    description: `There was an error restoring the backup. ${e}`,
                                    icon: (
                                        <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />
                                    ),
                                });

                                console.error(e);

                                reject();
                            }
                        }).catch(() => console.log('Oops errors!'));
                    } else {
                        setOpenRestore(true);
                        setRestoreObj(record);
                    }
                };

                return (
                    <div className={styles.BackupsActionsColumn}>
                        {currentRestore &&
                        !(
                            currentRestore?.isStatusCompleted() ||
                            currentRestore?.isStatusError()
                        ) ? (
                            <Spin />
                        ) : (
                            <AppConfirmDialog
                                onOk={onConfirmRestore}
                                actionIcon={<a>Restore</a>}
                                title={<strong>Restore a backup</strong>}
                                content="You are going to restore your database using the selected backup."
                            />
                        )}
                    </div>
                );
            },
        },
    ];

    const rowExpandable = (record: any) => {
        // row will be expandable if the backup has children
        return record.hasChildren;
    };

    const expandedRowRender = (record: any) => {
        return (
            <IncrementalBackups
                dataStoreUuid={deployment.dataStoreUuid}
                parentId={record.backupId}
                restoring={
                    currentRestore &&
                    !(
                        currentRestore?.isStatusCompleted() ||
                        currentRestore?.isStatusError()
                    )
                }
            />
        );
    };

    return backupsLoading ? (
        <AppLoading />
    ) : backups?.length > 0 ? (
        <section>
            {currentRestore && !currentRestore?.isClosed() && (
                <RestoringBackups currentRestore={currentRestore} />
            )}
            <AppTable
                columns={backupsColumns}
                data={backups}
                rowKey="backupId"
                expandedRowRender={expandedRowRender}
                rowExpandable={rowExpandable}
                pagination={{
                    pageSize,
                    current: page,
                    total,
                    hideOnSinglePage: true,
                }}
                onChange={handleTableChange}
                customIcon={true}
            />
            <BackupsModal
                visible={openRestore}
                currentBackup={restoreObj}
                dataStore={deployment.dataStoreUuid}
                onclose={(submitted) => {
                    setOpenRestore(false);
                    submitted && refreshRestores();
                }}
            />
        </section>
    ) : (
        <AppEmpty message="There are no Backups to display yet." />
    );
}
