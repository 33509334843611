import { ReactElement, Suspense, useEffect, useMemo, useState } from 'react';
import { Badge, Tabs, TabsProps, Button, Row, Col } from 'antd';
import { useHistory, useParams } from 'react-router';
import CcxComponentProps from '../../../core/CcxComponent';
import Firewalls from '../../firewalls/Firewalls';
import LazyLoader from '../../LazyLoader';
import QueryStats from '../../queryStats/QueryStats';
import styles from './DataStore.module.less';
import DataStoreOverview from './overview/DataStoreOverview';
import ProjectBreadcrumb from '../ProjectBreadcrumb';
import DataStoreSettings from './settings/DataStoreSettings';
import DataStoreServices from './services/DataStoreServices';
import DeploymentDashboard from '../../deployments/dashboard/DeploymentDashboard';
import { hideHeader } from '../../../core/CcxEnv';
import NotFoundError404 from '../../errors/NotFoundError404';
import useThrowError from '../../errors/useThrowError';
import DbDatabases from '../../dbUsers/DbDatabase';
import DbUsers from '../../dbUsers/DbUsers';
import Backups from '../../backups/Backups';
import { useAppSelector } from '../../../redux/hooks';
import EventViewer from '../../eventViewer/EventViewer';
import useDataStoreJobs from '../../../core/hooks/useDataStoreJobs';
import Job from '../../../types/Job';

interface UrlProps {
    activeTab: string;
    dataStoreUuid: string;
    projectUuid: string;
}

interface Props extends CcxComponentProps {}

function DataStore({ testId = 'DataStore' }: Props): ReactElement {
    const { throwError } = useThrowError();
    let history = useHistory();
    let { activeTab, dataStoreUuid, projectUuid } = useParams<UrlProps>();
    const [activeKey, setActiveKey] = useState<string>('overview');
    const [dbCount, setDbCount] = useState<number>(0);
    const [runningJobsCount, setRunningJobsCount] = useState(0);

    const { jobs } = useDataStoreJobs({
        dataStoreUuid,
    });

    useEffect(() => {
        if (jobs && jobs.length) {
            const runningJobsCount = jobs.filter((job: Job) =>
                job.isStatusRunning()
            ).length;

            setRunningJobsCount(runningJobsCount);
        }
    }, [jobs, setRunningJobsCount]);

    const { datastores: deployments } = useAppSelector(
        (state) => state.datastores
    );

    const deployment = useMemo(() => {
        if (deployments && deployments?.deployments) {
            return deployments?.getByUuid(dataStoreUuid);
        }
    }, [deployments, dataStoreUuid]);

    const switchTab = (tab: string) => {
        history.push(
            `/projects/${projectUuid}/data-stores/${dataStoreUuid}/${tab}`
        );
    };

    useEffect(() => {
        if (deployments && !deployment) {
            throwError(new NotFoundError404('Datastore not found'));
        }
    }, [deployments, deployment]);

    useEffect(() => {
        setActiveKey(activeTab);
    }, [activeTab]);

    const cancel = () => {
        history.push({
            pathname: '/',
        });
    };

    const items: TabsProps['items'] = [
        {
            key: 'overview',
            label: `Overview`,
            children: (
                <Suspense fallback={<LazyLoader />}>
                    <DataStoreOverview />
                </Suspense>
            ),
        },
        {
            key: 'services',
            label: `Nodes`,
            children: (
                <Suspense fallback={<LazyLoader />}>
                    <DataStoreServices
                        switchTab={switchTab}
                        dataStores={deployments}
                        setDbCount={setDbCount}
                    />
                </Suspense>
            ),
        },
        deployment && {
            key: 'monitoring',
            label: `Monitoring`,
            children: (
                <Suspense fallback={<LazyLoader />}>
                    <DeploymentDashboard currentDeployment={deployment} />
                </Suspense>
            ),
        },
        deployment && {
            key: 'eventViewer',
            label: (
                <>
                    Event Viewer
                    {runningJobsCount > 0 ? (
                        <Badge
                            count={runningJobsCount}
                            className={styles.TabsBadge}
                        />
                    ) : (
                        ''
                    )}
                </>
            ),
            children: (
                <Suspense fallback={<LazyLoader />}>
                    <EventViewer currentDeployment={deployment} />
                </Suspense>
            ),
        },
        deployment &&
            !deployment.isRedis() && {
                key: 'queryStats',
                label: `Query Stats`,
                children: (
                    <Suspense fallback={<LazyLoader />}>
                        <QueryStats />
                    </Suspense>
                ),
            },
        deployment &&
            !deployment.isRedis() && {
                key: 'dbUsers',
                label: `Users`,
                children: (
                    <Suspense fallback={<LazyLoader />}>
                        <DbUsers deployments={deployments} />
                    </Suspense>
                ),
            },
        deployment &&
            !deployment.isRedis() && {
                key: 'dbDatabases',
                label: `Databases`,
                children: (
                    <Suspense fallback={<LazyLoader />}>
                        <DbDatabases deployments={deployments} />
                    </Suspense>
                ),
            },
        {
            key: 'backups',
            label: `Backups`,
            children: (
                <Suspense fallback={<LazyLoader />}>
                    <Backups />
                </Suspense>
            ),
        },
        {
            key: 'firewall',
            label: `Firewall`,
            children: (
                <Suspense fallback={<LazyLoader />}>
                    <Firewalls />
                </Suspense>
            ),
        },
        {
            key: 'settings',
            label: (
                <>
                    Settings
                    {deployment?.can_upgrade_to ? (
                        <Badge count={1} className={styles.TabsBadge} />
                    ) : (
                        ''
                    )}
                </>
            ),
            children: (
                <Suspense fallback={<LazyLoader />}>
                    <DataStoreSettings dataStores={deployments} />
                </Suspense>
            ),
        },
    ].filter((item) => !!item) as TabsProps['items'];

    return (
        <section className={styles.DataStore} data-testid={testId}>
            <ProjectBreadcrumb
                deployments={deployments}
                testId={`${testId}Breadcrumb`}
            />

            <Tabs
                className={styles.DataStoreTabs}
                data-testid={`${testId}Tabs`}
                type="card"
                activeKey={activeKey}
                defaultActiveKey={activeTab}
                destroyInactiveTabPane={true}
                onChange={(tabKey: any) => {
                    history.push(
                        `/projects/${projectUuid}/data-stores/${dataStoreUuid}/${tabKey}`
                    );
                }}
                items={items}
            />

            {hideHeader && (
                <Row className={styles.DataStoreBackRow}>
                    <Col span={2} offset={22}>
                        <Button onClick={() => cancel()}>Back</Button>
                    </Col>
                </Row>
            )}
        </section>
    );
}

export default DataStore;
