import React, { ReactElement } from 'react';
import { Badge, Button, Dropdown, Menu, notification, Space } from 'antd';
import { NavLink } from 'react-router-dom';
import User from '../../types/User';
import { AUTH_APP_URL, AUTH_UI_APP_DISABLED } from '../../core/CcxEnv';
import CcxIconUserOutlined from '../ccx/icons/CcxIconUserOutlined';
import styles from './UserMenu.module.less';
import UsersService from '../../services/UsersService';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';

export type UserMenuProps = {
    user?: User | undefined;
};

/**
 * UserMenu Component
 * @param User user
 */
function UserMenu({ user }: UserMenuProps): ReactElement {
    if (!user) {
        return <div />;
    }

    const handleLogoutClick = () => {
        (async () => {
            try {
                await UsersService.logout();
                window.location.href = AUTH_APP_URL;
            } catch (e) {
                notification.open({
                    message: 'Logout error',
                    description: `An error occurred while logout: ${e}`,
                    icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
                });
            }
        })();
    };

    const logoutLink = AUTH_UI_APP_DISABLED ? (
        <span onClick={handleLogoutClick}>Logout</span>
    ) : (
        <a
            href={`${AUTH_APP_URL}/logout?from=ccx`}
            rel="noopener noreferrer"
            data-testid="UserMenuLogoutLink"
        >
            Logout
        </a>
    );
    const menu = (
        <Menu data-testid="UserMenu">
            {!AUTH_UI_APP_DISABLED && !user.emailConfirmed ? (
                <Menu.Item key="verify">
                    <a
                        target="_blank"
                        href={`${AUTH_APP_URL}/verify-account?initial=1&from=ccx&send=1`}
                        rel="noopener noreferrer"
                        data-testid="UserMenuVerifyEmailLink"
                    >
                        Re-send Verification Email
                    </a>
                </Menu.Item>
            ) : null}
            <Menu.Item key="account">
                <NavLink to="/account" data-testid="UserMenuAccountLink">
                    Account
                </NavLink>
            </Menu.Item>
            <Menu.Item key="logout">{logoutLink}</Menu.Item>
        </Menu>
    );

    return (
        user && (
            <Dropdown
                overlay={menu}
                trigger={['click']}
                placement="bottomRight"
                className={styles.UserMenu}
            >
                <Button type="link" data-testid="UserMenuButton">
                    <Space size={8}>
                        {user.emailConfirmed ? (
                            <CcxIconUserOutlined />
                        ) : (
                            <Badge dot={true} className={styles.UserMenuBadge}>
                                <CcxIconUserOutlined />
                            </Badge>
                        )}
                        <div className={styles.UserMenuName}>
                            {user.firstName} {user.lastName}
                        </div>
                    </Space>
                </Button>
            </Dropdown>
        )
    );
}

export default UserMenu;
