import Error404 from './Error404';
import React from 'react';
import { useHistory } from 'react-router-dom';

export default function Error404Page({}) {
    let history = useHistory();
    const handleHomeClick = () => {
        history.push({
            pathname: '/',
        });
    };

    return <Error404 onHomeClick={handleHomeClick} />;
}
