import BackupSchedule, { BackupScheduleType } from '../../types/BackupSchedule';
import { Button, ButtonProps } from 'antd';
import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import React from 'react';
import {
    backupSettingsApiInterface,
    BackupSettingsDTO,
    BackupSettingsPatchDTO,
} from '../../sdk/BackupsApi';
import DeploymentsItem from '../../types/DeploymentsItem';

type BackupsScheduleSwitchButtonProps = ButtonProps & {
    deployment: DeploymentsItem;
    schedule: BackupSchedule;
    onSuccess?: () => void;
};
export default function BackupsScheduleSwitchButton({
    deployment,
    schedule,
    onSuccess,
    ...rest
}: BackupsScheduleSwitchButtonProps) {
    const applyEnabled = async (enabled: boolean) => {
        try {
            let settings: BackupSettingsPatchDTO | undefined = undefined;
            if (schedule.backupType === BackupScheduleType.FULL) {
                settings = {
                    full: {
                        frequency: enabled ? 1 : 0,
                        cron: enabled ? schedule.getCronString() : undefined,
                    },
                };
            } else if (schedule.backupType === BackupScheduleType.INCREMENTAL) {
                settings = {
                    incremental: {
                        frequency: enabled ? 1 : 0,
                        cron: enabled ? schedule.getCronString() : undefined,
                    },
                };
            }
            if (settings) {
                await backupSettingsApiInterface.settingsDatastoreUuidPatch(
                    deployment.dataStoreUuid,
                    settings
                );
                onSuccess?.();
            } else {
                throw new Error(`Unknown backup type: ${schedule.backupType}`);
            }
        } catch (e: any) {
            console.error(e);
        }
    };
    const handlePause = async () => {
        try {
            await applyEnabled(false);
        } catch (e: any) {
            console.error(e);
        }
    };

    const handleEnable = async () => {
        try {
            await applyEnabled(true);
        } catch (e: any) {
            console.error(e);
        }
    };
    return schedule.isActive() ? (
        <Button
            onClick={handlePause}
            icon={<PauseCircleOutlined />}
            type={'text'}
        >
            Pause
        </Button>
    ) : (
        <Button
            onClick={handleEnable}
            icon={<PlayCircleOutlined />}
            type={'text'}
        >
            Enable
        </Button>
    );
}
