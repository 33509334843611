import React, { ReactElement, useState } from 'react';
import { Modal, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './AppConfirmDialog.module.less';
import { Input } from 'antd';
import { Alert } from 'antd';
import { message } from 'antd';
import { Form } from 'antd';
import CcxComponentProps from '../core/CcxComponent';
import Tooltip from 'antd/lib/tooltip';
import classNames from 'classnames';

interface Props extends CcxComponentProps {
    icon?: any;
    title?: any;
    alertTitle?: any;
    content: string;
    onOk?: Function;
    onCancel?: Function;
    label?: React.ReactNode;
    tooltip?: React.ReactNode;
    actionIcon?: any;
    confirmPhrase?: string;
    confirmHintText?: string;
    confirmPlaceholder?: string;
    disabled?: boolean;
    critical?: boolean;
}

function AppConfirmDialog({
    icon,
    title,
    alertTitle,
    content,
    onOk,
    onCancel,
    label,
    tooltip,
    actionIcon,
    confirmPhrase,
    confirmHintText,
    confirmPlaceholder,
    disabled,
    testId = 'AppConfirmDialog',
    critical,
}: Props): ReactElement {
    const { confirm } = Modal;
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();

    const handleClickIcon = () => {
        if (disabled) {
            return;
        }

        if (!confirmPhrase) {
            confirm({
                title: title || 'Are you sure?',
                icon: icon ?? <ExclamationCircleOutlined />,
                content: content,
                onOk() {
                    onOk && onOk();
                },
                onCancel() {
                    onCancel && onCancel();
                },
            });
        } else {
            setVisible(true);
        }
    };

    const verifyMatch = (value: string) => {
        return value === confirmPhrase;
    };

    const handleOk = () => {
        const phrase = form.getFieldValue('confirmation');
        if (verifyMatch(phrase)) {
            setVisible(false);
            form.setFieldsValue({ confirmation: '' });
            onOk && onOk();
        } else {
            message.error(
                'Incorrect confirmation. Did you enter the correct value?'
            );
        }
    };

    const handleCancel = () => {
        setVisible(false);
        onCancel && onCancel();
    };

    const handleKeyUpEvent = (event: any) => {
        if (event.key === 'Enter') {
            handleOk();
        }
    };

    return (
        <>
            <div
                className={classNames(styles.AppConfirmDialog, {
                    [styles.AppConfirmDialogDisabled]: disabled,
                    [styles.AppConfirmDialogCritical]: critical,
                })}
                onClick={handleClickIcon}
                data-testid={`${testId}IconLabel`}
            >
                {tooltip ? (
                    <Tooltip title={tooltip}>
                        <Space size={5}>
                            {actionIcon}
                            <span>{label}</span>
                        </Space>
                    </Tooltip>
                ) : (
                    <Space size={5}>
                        {actionIcon}
                        <span>{label}</span>
                    </Space>
                )}
            </div>
            {visible ? (
                <Modal
                    title={title || 'Are you sure?'}
                    open={visible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    data-testid={`${testId}Modal`}
                    destroyOnClose={true}
                >
                    <Alert
                        data-testid={`${testId}Alert`}
                        message={
                            <div
                                className={styles.AppConfirmDialogAlertMessage}
                                data-testid="AppConfirmDialogAlertMessage"
                            >
                                {alertTitle || 'Warning'}
                            </div>
                        }
                        description={
                            <div
                                className={
                                    styles.AppConfirmDialogAlertDescription
                                }
                                data-testid="AppConfirmDialogAlertDescription"
                            >
                                {content}
                            </div>
                        }
                        type="warning"
                        showIcon
                    />
                    <br />
                    <p data-testid={`${testId}AlertConfirmText`}>
                        {confirmHintText}
                    </p>
                    <Form
                        layout="horizontal"
                        form={form}
                        scrollToFirstError={true}
                        onKeyUp={handleKeyUpEvent}
                    >
                        <Form.Item name="confirmation" noStyle>
                            <Input
                                autoComplete="off"
                                data-testid={`${testId}AlertConfirmInput`}
                                placeholder={confirmPlaceholder}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            ) : null}
        </>
    );
}

export default AppConfirmDialog;
