import React, { ReactElement, useState, useEffect } from 'react';
import { Button, Row, Col, Form, message, Modal } from 'antd';
import styles from './ButtonModalForm.module.less';
import AppForm from './ccx/common/AppForm';
import CcxComponentProps from '../core/CcxComponent';
import { Tooltip } from 'antd';
import { FormLayout } from 'antd/lib/form/Form';

interface Props extends CcxComponentProps {
    title: string | ReactElement;
    buttonText: string;
    buttonIcon?: ReactElement;
    onSubmit: Function;
    onSuccess: Function;
    onCancel?: Function;
    fields?: any[];
    children?: ReactElement;
    buttonType?: string;
    buttonClassName?: string;
    formValues?: any;
    formLayout?: FormLayout;
    overideValidationFunction?: Function;
    disabled?: boolean;
    disabledText?: string;
    formClassName?: string;
    submitText?: string;
    cancelText?: string;
    width?: number;
    leftCol?: number;
    rightCol?: number;
    useGrid?: boolean;
    onFormLoad?: Function;
}

function ButtonModalForm({
    title,
    buttonText,
    buttonIcon,
    className,
    onSubmit,
    onSuccess,
    onCancel,
    fields,
    children,
    buttonType,
    buttonClassName,
    formValues,
    formLayout,
    overideValidationFunction,
    testId,
    disabled,
    disabledText,
    formClassName,
    submitText = 'Submit',
    cancelText = 'Cancel',
    width = 720,
    leftCol = 5,
    rightCol = 4,
    useGrid,
    onFormLoad,
}: Props): ReactElement {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [errorFields, setErrorFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [initialFormValues, setInitialFormValues] = useState({});

    useEffect(() => {
        if (form && onFormLoad) {
            onFormLoad(form);
        }
    }, [form, onFormLoad]);

    useEffect(() => {
        if (formValues) {
            setInitialFormValues(formValues);
        }
    }, [formValues]);

    const validateForm = async () => {
        if (overideValidationFunction) {
            return overideValidationFunction();
        }

        try {
            const a = await form.validateFields();
            return true;
        } catch (e: any) {
            setErrorFields(e.errorFields);
            message.error(
                'One or more fields have errors. Please double check and try again.'
            );
            return false;
        }
    };

    const onSubmitButtonClick = async () => {
        const stepValid = await validateForm();

        if (stepValid) {
            setLoading(true);
            try {
                await onSubmit(form.getFieldsValue());
                onSuccess && onSuccess();
                onClose();
            } catch (e) {
                console.log('error e=', e);
            }
            setLoading(false);
        }
    };

    const onButtonClick = () => {
        setVisible(true);
    };

    const onClose = () => {
        form.resetFields();
        setErrorFields([]);
        setVisible(false);
        onCancel && onCancel();
    };

    return (
        <>
            <Tooltip title={disabled ? disabledText : ''}>
                <Button
                    onClick={onButtonClick}
                    disabled={disabled}
                    icon={buttonIcon}
                    type={buttonType as any}
                    className={
                        className || buttonType === 'simple'
                            ? `${styles.ButtonModalFormTypeSimple} ${buttonClassName}`
                            : buttonClassName
                    }
                    data-testid={testId || 'ButtonModalForm'}
                >
                    {buttonText}
                </Button>
            </Tooltip>
            <Modal
                title={title}
                open={visible}
                data-testid={`${testId}Modal`}
                width={width}
                onCancel={onClose}
                footer={
                    <Row justify="end">
                        <Col>
                            <Button
                                data-testid={`${testId}CancelButton`}
                                onClick={onClose}
                            >
                                {cancelText}
                            </Button>
                            <Button
                                data-testid={`${testId}SubmitButton`}
                                loading={loading}
                                onClick={onSubmitButtonClick}
                                type="primary"
                            >
                                {submitText}
                            </Button>
                        </Col>
                    </Row>
                }
            >
                {children}
                <Form
                    layout={formLayout}
                    form={form}
                    scrollToFirstError={true}
                    initialValues={{ ...initialFormValues }}
                >
                    <AppForm
                        formClassName={formClassName}
                        fields={fields}
                        onPressEnter={onSubmitButtonClick}
                        errorFields={errorFields}
                        useGrid={useGrid}
                    />
                </Form>
            </Modal>
        </>
    );
}

export default ButtonModalForm;
