import React, { ReactElement } from 'react';
import styles from './CopyToClipboardText.module.less';
import { message, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import Hoverable from './Hoverable';
import classNames from 'classnames';
import AppButton from './ccx/common/AppButton';

export type CopyToClipboardTextProps = {
    text?: string;
    preview?: string;
    hoverable?: boolean;
    tooltip?: string;
    nowrap?: boolean;
    buttonProps?: any;
    successMessage?: string;
    showPreview?: boolean;
};

function CopyToClipboardText({
    text,
    preview,
    hoverable = true,
    tooltip = 'Click to copy to clipboard.',
    nowrap,
    buttonProps = {},
    successMessage,
    showPreview = true,
}: CopyToClipboardTextProps): ReactElement {
    const handleClick = () => {
        copyMessage(text);
        message.success(successMessage || 'Copied to clipboard.');
    };

    const copyMessage = (val: string | undefined) => {
        if (!val) {
            return;
        }
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    };

    return hoverable ? (
        <Hoverable icon={<CopyOutlined />}>
            <Tooltip
                placement="bottom"
                title={tooltip}
                className={classNames(styles.CopyToClipboardText, {
                    [styles.CopyToClipboardTextNowrap]: nowrap,
                })}
                {...{ onClick: handleClick }}
            >
                <span>{preview ? preview : text}</span>
            </Tooltip>
        </Hoverable>
    ) : (
        <Tooltip
            placement="bottom"
            title={tooltip}
            className={classNames(styles.CopyToClipboardText, {
                [styles.CopyToClipboardTextNowrap]: nowrap,
            })}
            {...{ onClick: handleClick }}
        >
            <span>
                {showPreview ? (preview ? preview : text) : ''}{' '}
                <AppButton
                    type="link"
                    icon={<CopyOutlined />}
                    {...buttonProps}
                />
            </span>
        </Tooltip>
    );
}

export default CopyToClipboardText;
