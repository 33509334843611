import React, { ReactElement } from 'react';
import styles from './LazyLoader.module.less';
import { Spin, Skeleton } from 'antd';
import CcxComponentProps from '../core/CcxComponent';

interface Props extends CcxComponentProps {
    active?: boolean;
    avatarShape?: string;
    buttonShape?: string;
    rows?: number;
    size?: string;
    type?: string;
}

/**
 * Skeleton for React's lazy loader
 */
function LazyLoader({
    active = true,
    avatarShape = 'circle',
    buttonShape = 'default',
    rows = 4,
    size = 'default',
    type = 'spinner',
    testId = 'LazyLoader',
}: Props): ReactElement {
    switch (type) {
        case 'row':
            return (
                <Skeleton
                    active={active}
                    paragraph={{ rows }}
                    data-testid={testId}
                    className={styles.LazyLoaderMargin}
                    {...{ size: size }}
                />
            );
        case 'avatar':
            return (
                <Skeleton.Avatar
                    active={active}
                    size={size as any}
                    shape={avatarShape as any}
                    data-testid={testId}
                />
            );
        case 'button':
            return (
                <Skeleton.Button
                    active={active}
                    size={size as any}
                    shape={buttonShape as any}
                    data-testid={testId}
                />
            );
        case 'image':
            return (
                <Skeleton.Image
                    active={active}
                    data-testid={testId}
                    {...{ size: size }}
                />
            );
        case 'input':
            return (
                <Skeleton.Input
                    active={active}
                    size={size as any}
                    data-testid={testId}
                />
            );
        case 'spinner':
        default:
            return <Spin data-testid={testId} />;
    }
}

export default LazyLoader;
