import React, { ReactElement } from 'react';
import CcxComponentProps from '../../../../core/CcxComponent';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import Service from '../../../../types/Service';
import AppCodeCard from '../../../AppCodeCard';
import SecretText from '../../../SecretText';
import styles from './ServiceAccessConnectionInformation.module.less';
import useDatastoreServiceDsn from './useDatastoreServiceDsn';

interface Props extends CcxComponentProps {
    dataStore: DeploymentsItem;
    service: Service;
    type?: string;
}

function ServiceAccessConnectionInformation({
    dataStore,
    service,
    type = 'db',
    testId = 'ServiceAccessConnectionInformation',
}: Props): ReactElement {
    const { username, password, database, dbType } = useDatastoreServiceDsn({
        dataStore,
        service,
        type,
    });

    const connectionString = `${dbType}://${username}:${password}@${dataStore?.getPrimaryReplicaUrl(
        service
    )}${!dataStore?.isRedis() ? `/${database}` : ''}`;

    return (
        <div
            className={styles.ServiceAccessConnectionInformation}
            data-testid={testId}
        >
            <AppCodeCard
                className={styles.ServiceAccessConnectionInformationCode}
                usageExample={[connectionString]}
            >
                <div
                    className={
                        styles.ServiceAccessConnectionInformationCodeContent
                    }
                >
                    <div>{dbType}</div>
                    <div>://</div>
                    <SecretText
                        visibleText={`_CLICK_TO_REVEAL_PASSWORD_`}
                        hiddenText={`${username}:${password}`}
                        tooltipText="Click to show / hide username and password"
                    />
                    <div>{`@${dataStore?.getPrimaryReplicaUrl(service)}${
                        !dataStore?.isRedis() ? `/${database}` : ''
                    }`}</div>
                </div>
            </AppCodeCard>
        </div>
    );
}

export default ServiceAccessConnectionInformation;
