import Icon from '@ant-design/icons/lib/components/Icon';
import CloudProvider from '../../../types/CloudProvider';

export const getCloudProviderLogo = (p: CloudProvider, extended?: boolean) => {
    if (p) {
        const url = p.getCloudProviderLogo();

        return (
            <Icon
                component={() => (
                    <img src={url} alt={url} width={20} height={20} />
                )}
            />
        );
    }
    return null;
};
