import { ReactElement, useEffect } from 'react';
import AppEmpty from '../AppEmpty';
import { useParams } from 'react-router-dom';
import AppTable from '../AppTable';
import AppLoading from '../AppLoading';
import CcxSectionHeader from '../ccx/common/CcxSectionHeader';
import DeploymentsItem from '../../types/DeploymentsItem';
import useDataStoreJobs from '../../core/hooks/useDataStoreJobs';
import Job from '../../types/Job';
import FriendlyDateTime from '../../types/FriendlyDateTime';
import useDataStoreServices from '../../core/hooks/useDataStoreServices';
import DbService from '../../types/DbService';
import styles from './EventViewer.module.less';
import EventStatus from './EventStatus';

interface UrlProps {
    dataStoreUuid: string;
}

type EventViewerProps = {
    currentDeployment?: DeploymentsItem;
};

function EventViewer({ currentDeployment }: EventViewerProps): ReactElement {
    let { dataStoreUuid } = useParams<UrlProps>();

    const { services: nodes, loading: loadingNodes } = useDataStoreServices({
        dataStoreUuid,
    });

    const { jobs, loading, refresh } = useDataStoreJobs({
        dataStoreUuid,
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            refresh(true);
        }, 20000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const formatDate = (date: string) => {
        return new FriendlyDateTime({
            date,
        }).getDate();
    };

    const eventColumns = [
        {
            title: 'When',
            render: (record: Job) => {
                return formatDate(record.getDateForEventViewer());
            },
            sorter: (a: any, b: any) => {
                return (
                    new Date(a.getDateForEventViewer()).getTime() -
                    new Date(b.getDateForEventViewer()).getTime()
                );
            },
            key: 'when',
        },
        {
            title: 'Status',
            key: 'status',
            render: (record: Job) => {
                return (
                    <EventStatus
                        type={record.getStatusType()}
                        showTooltips={false}
                        job={record}
                    />
                );
            },
        },
        {
            title: 'Initiated by',
            key: 'user',
            render: (record: Job) => {
                if (currentDeployment?.accountId === record.user) return 'User';

                return 'System';
            },
        },
        {
            title: 'Description',
            key: 'type',
            render: (record: Job) => {
                const relatedNode = nodes?.dbServices?.find(
                    (node: DbService) =>
                        node.getServiceUuid() === record.data?.HostUUID
                );

                return record.getDescription(relatedNode);
            },
        },
    ];

    return (
        <section className={styles.EventViewer}>
            <CcxSectionHeader />
            {loading || loadingNodes ? (
                <AppLoading />
            ) : jobs?.length && nodes?.dbServices?.length ? (
                <AppTable
                    columns={eventColumns}
                    data={jobs}
                    rowKey="jobId"
                    pagination={{
                        hideOnSinglePage: true,
                        pageSize: 25,
                    }}
                />
            ) : (
                <AppEmpty message="There are no jobs created yet" />
            )}
        </section>
    );
}

export default EventViewer;
