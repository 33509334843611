import React, { ReactElement } from 'react';
import styles from './AppHeader.module.less';
import { Layout } from 'antd';
import AppLogo from './AppLogo';
import AppCreateButton from './AppCreateButton';
import AppUserMenu from './AppUserMenu';
import { userMenuDisabled } from '../../core/CcxEnv';
import AppHeaderNotifications from './AppHeaderNotifications';

function AppHeader(): ReactElement {
    const { Header } = Layout;

    return (
        <Header className={styles.AppHeader} data-testid="AppHeader">
            <div>
                <AppLogo />
            </div>
            <div>
                <AppCreateButton />
                <AppHeaderNotifications />
                {!userMenuDisabled && <AppUserMenu />}
            </div>
        </Header>
    );
}

export default AppHeader;
