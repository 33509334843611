import { Spin, Tooltip } from 'antd';
import { ReactElement, useEffect } from 'react';
import styles from './DataStoreStatus.module.less';
import DeploymentsItem from '../../../types/DeploymentsItem';
import CcxComponentProps from '../../../core/CcxComponent';
import DotLabelStatus from '../../ccx/common/DotLabelStatus';
import SmallContainer from '../../ccx/common/SmallContainer';
import useDataStoreJobs from '../../../core/hooks/useDataStoreJobs';
import Job from '../../../types/Job';

interface Props extends CcxComponentProps {
    dataStore: DeploymentsItem;
    updating?: boolean;
}

function DataStoreStatus({
    dataStore,
    testId = 'DataStoreStatus',
    updating = false,
}: Props): ReactElement {
    const dataStoreUuid = dataStore?.getUUID();

    const { jobs, refresh } = useDataStoreJobs({
        dataStoreUuid,
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            refresh(true);
        }, 20000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    let StatusComponent = null;

    if (dataStore?.isStatusOk()) {
        StatusComponent = (
            <DotLabelStatus
                type="ok"
                label={dataStore?.getDataStoreStatus()}
                glow={updating}
                testId={`${testId}StatusComponent`}
            />
        );
    } else if (dataStore?.isStatusError()) {
        StatusComponent = (
            <DotLabelStatus
                type="error"
                label={dataStore?.getDataStoreStatus()}
                glow={updating}
                testId={`${testId}StatusComponent`}
            />
        );
    } else if (dataStore?.isStatusWarning()) {
        StatusComponent = (
            <DotLabelStatus
                type="warning"
                label={dataStore?.getDataStoreStatus()}
                glow={updating}
                testId={`${testId}StatusComponent`}
            />
        );
    } else if (dataStore?.isDeleting()) {
        StatusComponent = (
            <DotLabelStatus
                type="normal"
                label={dataStore?.getDataStoreStatus()}
                glow={updating}
                testId={`${testId}StatusComponent`}
            />
        );
    } else if (dataStore?.isDeploying()) {
        StatusComponent = (
            <DotLabelStatus
                type="info"
                label={`${dataStore?.getDataStoreStatus()}... ${dataStore?.getDeploymentProgressText()}`}
                glow={updating}
                testId={`${testId}StatusComponent`}
            />
        );
    } else {
        StatusComponent = (
            <DotLabelStatus
                type="normal"
                label={dataStore?.getDataStoreStatus()}
                glow={updating}
                testId={`${testId}StatusComponent`}
            />
        );
    }

    const tooltipTitle = dataStore?.isStatusError()
        ? `${dataStore?.getNotes()}`
        : dataStore?.isStatusWarning()
        ? null
        : `${dataStore?.getDataStoreStatusText()}`;

    const top = (
        <Tooltip placement="bottom" title={tooltipTitle}>
            {StatusComponent}{' '}
        </Tooltip>
    );

    const getLatestRunningJob = () => {
        if (jobs) {
            const runningJob = jobs.find((job: Job) => job.isStatusRunning());

            return runningJob?.getStatusTextByType();
        }
    };

    const middle: any = getLatestRunningJob() && (
        <div className={styles.DatastoreJobStatusIndicator}>
            <Spin size="small" />
            {getLatestRunningJob()}
        </div>
    );

    return (
        <SmallContainer
            testId={testId}
            topChildren={top}
            middleChildren={middle}
        />
    );
}

export default DataStoreStatus;
