import { useEffect, useMemo } from 'react';
import { fetchJobs, refreshJobs } from '../../slices/jobs.slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

interface Props {
    dataStoreUuid?: string;
    offset?: number;
    limit?: number;
}

export default function useDataStoreJobs({
    dataStoreUuid,
    limit = 50,
    offset = 0,
}: Props) {
    const dispatch = useAppDispatch();

    const { jobs, initiallyLoaded, loading, error } = useAppSelector(
        (state) => state.jobs
    );

    const datastoreJobs = useMemo(() => {
        return dataStoreUuid ? jobs[dataStoreUuid] : [];
    }, [jobs, dataStoreUuid]);

    useEffect(() => {
        if (dataStoreUuid && !initiallyLoaded[dataStoreUuid]) {
            // Dispatch the async action to fetch jobs
            dispatch(fetchJobs({ dataStoreUuid, limit, offset }));
        }
    }, [dataStoreUuid, dispatch, limit, offset, initiallyLoaded]);

    const refresh = (ignoreLoader: boolean = false) => {
        if (dataStoreUuid) {
            if (ignoreLoader) {
                dispatch(refreshJobs({ dataStoreUuid, limit, offset }));
            } else {
                // Dispatch the async action to fetch jobs
                dispatch(fetchJobs({ dataStoreUuid, limit, offset }));
            }
        }
    };

    return { jobs: datastoreJobs, refresh, error, loading };
}
