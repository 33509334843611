import React, { ReactElement, useState } from 'react';
import { Tag, notification, Modal } from 'antd';
import styles from './Firewalls.module.less';
import AppEmpty from '../AppEmpty';
import { useParams } from 'react-router-dom';
import useFirewalls from '../../core/hooks/useFirewalls';
import AppTable from '../AppTable';
import AddFirewallRule from './AddFirewallRule';
import AppConfirmDialog from '../AppConfirmDialog';
import FirewallService from '../../services/FirewallService';
import AppLoading from '../AppLoading';
import CcxSectionHeader from '../ccx/common/CcxSectionHeader';
import CcxIconCheckCircleTwoTone from '../ccx/icons/CcxIconCheckCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import CcxIconInfoCircleTwoTone from '../ccx/icons/CcxIconInfoCircleTwoTone';
import CcxIconDeleteOutlined from '../ccx/icons/CcxIconDeleteOutlined';

interface UrlProps {
    dataStoreUuid: string;
}

function Firewalls(): ReactElement {
    let { dataStoreUuid } = useParams<UrlProps>();
    const [deleting, setDeleting] = useState<boolean>(false);
    const { firewalls, refresh, loading } = useFirewalls(dataStoreUuid);

    const columns = [
        {
            title: 'From',
            dataIndex: 'fromPort',
            key: 'fromPort',
            width: 100,
        },
        {
            title: 'To',
            dataIndex: 'toPort',
            key: 'toPort',
            width: 100,
        },
        {
            title: 'Source',
            dataIndex: 'source',
            key: 'source',
            responsive: ['md'],
            width: 300,
        },
        {
            title: 'Protocol',
            key: 'protocol',
            width: 110,
            dataIndex: 'protocol',
            render: (value: string) => (
                <Tag color="default" key={value}>
                    {value.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            responsive: ['lg'],
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 85,
            render: (text: string, record: any) => {
                const onConfirmDelete = () => {
                    return new Promise(async (resolve, reject) => {
                        setDeleting(true);

                        // if (deployment) {
                        notification.open({
                            message: 'Remove trusted source',
                            description: 'The source will be removed soon.',
                            icon: <CcxIconInfoCircleTwoTone />,
                        });

                        Modal.destroyAll();

                        try {
                            const result = await FirewallService.deleteRule({
                                rule: record,
                                uuid: dataStoreUuid,
                            });

                            refresh && (await refresh());

                            notification.open({
                                message: 'Remove trusted source',
                                description: 'Source successfully removed',
                                icon: (
                                    <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />
                                ),
                            });

                            setDeleting(false);
                            resolve(null);
                        } catch (e) {
                            notification.open({
                                message: 'Remove trusted source',
                                description: `There was an error removing the source. ${e}`,
                                icon: (
                                    <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />
                                ),
                            });

                            console.error(e);

                            setDeleting(false);
                            reject();
                        }

                        // updateDeploymentsList();

                        // }
                    }).catch(() => console.log('Oops errors!'));
                };

                return (
                    <div className={styles.FirewallsOptionsColumn}>
                        <AppConfirmDialog
                            critical={true}
                            onOk={onConfirmDelete}
                            content="The source will be removed."
                            actionIcon={<CcxIconDeleteOutlined />}
                        />
                    </div>
                );
            },
        },
    ];

    return (
        <section className={styles.Firewalls}>
            <CcxSectionHeader>
                <AddFirewallRule onSuccess={refresh} uuid={dataStoreUuid} />
            </CcxSectionHeader>
            {loading ? (
                <AppLoading />
            ) : firewalls ? (
                <div className={styles.FirewallsTable}>
                    <AppTable
                        columns={columns}
                        data={firewalls}
                        rowKey="uniqueKey"
                        pagination={{
                            hideOnSinglePage: true,
                        }}
                    />
                </div>
            ) : (
                <AppEmpty message="There are no firewall rules yet" />
            )}
        </section>
    );
}

export default Firewalls;
