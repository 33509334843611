import React, { ReactElement } from 'react';
import styles from './AppContent.module.less';
import AppRoute from '../../AppRoute';
import { Alert } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { notification } from 'antd';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import AppContentTemplate from './AppContentTemplate';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { updateProfile } from '../../slices/user.slice';

interface UrlProps {
    projectUuid: string;
}

function AppContent(): ReactElement {
    let { projectUuid } = useParams<UrlProps>();

    const { user } = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();

    const onAlertClose = async () => {
        try {
            dispatch(updateProfile());
        } catch (e) {
            notification.open({
                message: 'New User',
                description: `There was an error . ${e}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });
        }
    };

    return (
        <AppContentTemplate>
            {user?.isNewUser() && (
                <Alert
                    message={
                        <>
                            You have successfully completed your account. Enjoy
                            CCX by{' '}
                            <Link
                                to={`/projects/${projectUuid}/data-stores/add`}
                            >
                                <strong>creating your first data store.</strong>
                            </Link>
                        </>
                    }
                    type="success"
                    className={styles.AppContentContainerAlert}
                    showIcon
                    onClose={onAlertClose}
                    closable
                />
            )}
            <AppRoute />
        </AppContentTemplate>
    );
}

export default AppContent;
