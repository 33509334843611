import React, { ReactElement } from 'react';
import { Button, Space } from 'antd';
import styles from './Error404.module.less';
import { ReactComponent as Error404Image } from './Error404Image.svg';
import TypographyText from '../../tmp/TypographyText';

type Error404Props = {
    onHomeClick?: () => void;
};

/**
 * Error 404 Page
 */
function Error404({ onHomeClick }: Error404Props): ReactElement {
    return (
        <Space direction="vertical" className={styles.Error404Base}>
            <Error404Image />
            <Space direction="vertical" className={styles.Error404Details}>
                <h1 className={styles.Error404DetailsTitle}>Page not found</h1>
                <Space direction="vertical" size={40}>
                    <TypographyText>
                        The content you're looking for doesn't exist. Either it
                        was removed, or you mistyped the link.
                    </TypographyText>
                    <Button type="primary" onClick={onHomeClick}>
                        Go to home
                    </Button>
                </Space>
            </Space>
        </Space>
    );
}

export default Error404;
