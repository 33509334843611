import { Col, Row } from 'antd';
import CcxComponentProps from '../../../../core/CcxComponent';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import styles from './DataStoreOverviewAccessToServices.module.less';
import ServiceAccessConnectionInformation from '../services/ServiceAccessConnectionInformation';
import LazyLoader from '../../../LazyLoader';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import { sortNodesByRole } from '../../../../core/helpers';
import Service from '../../../../types/Service';

interface Props extends CcxComponentProps {
    dataStore: DeploymentsItem | undefined;
    services?: any;
    showTitle: boolean;
}

function DataStoreOverviewAccessToServices({
    dataStore,
    services,
    showTitle,
    testId = 'DataStoreOverviewAccessToServices',
}: Props) {
    const getRoleNameWithInfo = (u: any) => {
        if (u?.role === 'slave' || u?.role === 'SECONDARY') {
            return (
                <div
                    className={styles.DataStoreOverviewAccessToServicesRoleName}
                >
                    <span>Replica</span>
                    <InfoIcon
                        info={
                            <span>The replica points to a read-only node.</span>
                        }
                    />
                </div>
            );
        } else {
            return (
                <div
                    className={styles.DataStoreOverviewAccessToServicesRoleName}
                >
                    <span>Primary</span>
                    <InfoIcon
                        info={
                            <span>The primary points to a writeable node.</span>
                        }
                    />
                </div>
            );
        }
    };

    const uniqueRolesMap: Record<string, boolean> = {}; // Object to keep track of unique roles
    const sortedDbServices =
        services && sortNodesByRole(services?.dbServices, 'master');

    const uniqueRolesArray = (sortedDbServices || []).filter(
        (service: Service) => {
            const role = service.getRole();

            if (!uniqueRolesMap[role]) {
                if (
                    ((role === 'master' || role === 'PRIMARY') &&
                        dataStore?.getPrimaryUrl()) ||
                    ((role === 'slave' || role === 'SECONDARY') &&
                        dataStore?.getReplicaUrl())
                ) {
                    uniqueRolesMap[role] = true;
                    return true;
                }
            }
            return false;
        }
    );

    return (
        <>
            <div className={styles.DataStoreOverviewAccessToServices}>
                {showTitle && <header>Connection information</header>}

                {dataStore && uniqueRolesArray ? (
                    <Row gutter={[16, 16]}>
                        {uniqueRolesArray?.map((s: Service) => {
                            return (
                                <>
                                    <Col
                                        span={3}
                                        className={
                                            styles.DataStoreOverviewAccessToServicesRole
                                        }
                                    >
                                        {getRoleNameWithInfo(s)}
                                    </Col>
                                    <Col
                                        span={21}
                                        className={
                                            styles.DataStoreOverviewAccessToServicesConInfo
                                        }
                                    >
                                        <ServiceAccessConnectionInformation
                                            dataStore={dataStore}
                                            service={s}
                                        />
                                    </Col>
                                </>
                            );
                        })}
                    </Row>
                ) : (
                    <LazyLoader
                        type="row"
                        testId={`${testId}Loader`}
                        rows={2}
                    />
                )}
            </div>
        </>
    );
}
export default DataStoreOverviewAccessToServices;
