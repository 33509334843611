import React, { ReactElement, useEffect, useState } from 'react';
import styles from './DeploymentCompare.module.less';
import { Card, Tabs } from 'antd';
import { StickyContainer, Sticky } from 'react-sticky';
import { withRouter, useParams } from 'react-router-dom';
import AppLabeledComponent from '../../ccx/common/AppLabeledComponent';
import AppRangePicker from '../../ccx/common/AppRangePicker';
import DeploymentDashboardOptions from '../dashboard/DeploymentDashboardOptions';
import { Select } from 'antd';
import useDataStore from '../../../core/hooks/useDataStore';
import LbNode from '../../../types/LbNode';
import DbNode from '../../../types/DbNode';
import HostCompareDashboard from './HostCompareDashboard';
import DbCompareDashboard from './DbCompareDashboard';
import LbCompareDashboard from './LbCompareDashboard';
import { message } from 'antd';
import dayjs from 'dayjs';

interface Props {}

interface UrlProps {
    dataStoreUuid: string;
    projectUuid: string;
    tab: string;
}

function DeploymentCompare({}: Props): ReactElement {
    let { dataStoreUuid, projectUuid, tab } = useParams<UrlProps>();
    const { dataStore: deployment } = useDataStore(dataStoreUuid);
    const [refreshInterval, setRefreshInterval] = useState(15000);
    const [displaySummary, setDisplaySummary] = useState(false);
    const [rangeFrom, setRangeFrom] = useState<string | undefined>(undefined);
    const [rangeTo, setRangeTo] = useState<string | undefined>(undefined);
    const [range, setRange] = useState('30m');
    const [currentTab, setCurrentTab] = useState('host');
    const [selectedNodes, setSelectedNodes] = useState<any[]>([]);
    const [availableNodes, setAvailableNodes] = useState<any>({
        hosts: [],
        dbNodes: [],
        lbNodes: [],
    });
    const [field, setField] = useState<any>(undefined);

    const fieldSetup = {
        placeholder: 'Select up to 3 Hosts or Nodes to compare',
        disabled: false,
        options: [],
    };

    useEffect(() => {
        // selectFirstInstanceHost();
        if (deployment) {
            // load available lb nodes
            const availableLbNodes = deployment
                .getLbNodes()
                .map((n: LbNode) => {
                    return {
                        value: `${n.privateIp}:${n.port}`,
                        label: n.getFqdnPort(),
                    };
                });
            // load avialable db nodes
            const availableDbNodes = deployment
                .getDbNodes()
                .map((n: DbNode) => {
                    return {
                        value: `${n.privateIp}:${n.port}`,
                        label: n.getFqdnPort(),
                    };
                });

            // load available unique hosts
            let filteredLbHosts = availableLbNodes.map((n: any) => {
                return { value: n.value, label: n.label.split(':')[0] };
            });

            let filteredDbHosts = availableDbNodes.map((n: any) => {
                return { value: n.value, label: n.label.split(':')[0] };
            });

            let availableHosts = filteredDbHosts;

            filteredLbHosts.map((item: any) => {
                let hFound = false;
                availableHosts.forEach((i: any) => {
                    if (i.label === item.label) {
                        hFound = true;
                    }
                });
                if (!hFound) {
                    availableHosts.push(item);
                }
            });

            setAvailableNodes({
                hosts: availableHosts,
                lbNodes: availableLbNodes,
                dbNodes: availableDbNodes,
            });
        }
    }, [deployment]);

    useEffect(() => {
        if (availableNodes) {
            const newField = fieldSetup;
            switch (currentTab) {
                case 'db':
                    newField.options = availableNodes.dbNodes;
                    setField(newField);
                    break;
                case 'lb':
                    newField.options = availableNodes.lbNodes;
                    setField(newField);
                    break;
                case 'host':
                default:
                    newField.options = availableNodes.hosts;
                    setField(newField);
            }
        }
    }, [availableNodes, currentTab]);

    const handleIntervalChange = (interval: number) => {
        setRefreshInterval(interval * 1000);
    };

    const handleDisplaySummaryChange = (value: boolean) => {
        setDisplaySummary(value);
    };

    const handleRangeChange = (from: any, to?: number) => {
        // from and to parameters are set only for custom ranges
        if (to && from) {
            setRangeTo(dayjs(to * 1000).format());
            setRangeFrom(dayjs(from * 1000).format());
            setRange('custom');
        } else {
            // must unset "to" when only "from" was passed
            setRangeTo(undefined);

            if (from) {
                // from is always passed, but in this case it's not a number but the value of the radio group
                // which is a string like '1d'
                const minutes = 1000 * 60;
                const hours = minutes * 60;
                const days = hours * 24;
                let date = new Date();

                switch (from) {
                    case '30m':
                        date.setTime(date.getTime() - 30 * minutes);
                        break;
                    case '1h':
                        date.setTime(date.getTime() - 1 * hours);
                        break;
                    case '1d':
                        date.setTime(date.getTime() - 1 * days);
                        break;
                    case '1w':
                        date.setTime(date.getTime() - 7 * days);
                        break;
                }

                setRangeFrom(date.toISOString());
                setRange(from);
            }
        }
    };

    const onTabChange = (t: any) => {
        setSelectedNodes([]);
        setCurrentTab(t);
    };

    const { TabPane } = Tabs;
    const { Option } = Select;

    const handleNodeChange = (value: any) => {
        if (value.length > 3) {
            message.error('Warning: Too many nodes have been selected.');
            return;
        }
        setSelectedNodes(value);
    };

    const extraTabContent = (
        <div className={styles.DeploymentCompareExtraTabContent}>
            <AppLabeledComponent
                label={`${currentTab === 'host' ? 'Hosts' : 'Nodes'}:`}
            >
                <div className={styles.DeploymentCompareExtraTabContentSelect}>
                    <Select
                        showSearch
                        mode="multiple"
                        placeholder={field?.placeholder}
                        optionFilterProp="children"
                        onChange={handleNodeChange}
                        onDeselect={handleNodeChange}
                        maxTagCount={2}
                        filterOption={(input: any, option: any) =>
                            option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {field?.options.map((o: any) => {
                            return (
                                <Option
                                    value={o.value}
                                    key={o.value}
                                    disabled={
                                        selectedNodes.length > 3
                                            ? selectedNodes.includes(o.value)
                                                ? false
                                                : true
                                            : false
                                    }
                                >
                                    {o.label}
                                </Option>
                            );
                        })}
                    </Select>
                </div>
            </AppLabeledComponent>
        </div>
    );

    const renderTabBar = (props: any, DefaultTabBar: any) => (
        <Sticky bottomOffset={80}>
            {({ style }: any) => (
                <DefaultTabBar
                    {...props}
                    className={styles.DeploymentCompareCustomTabBar}
                    style={{ ...style }}
                />
            )}
        </Sticky>
    );

    return (
        <section className={styles.DeploymentCompare}>
            <Card className={styles.DeploymentCompareRangePickerCard}>
                <div className={styles.DeploymentCompareRangePickerCardContent}>
                    <div
                        className={
                            styles.DeploymentCompareRangePickerCardContentLeft
                        }
                    >
                        <DeploymentDashboardOptions
                            value={refreshInterval / 1000}
                            onChange={handleIntervalChange}
                            onSummaryChange={handleDisplaySummaryChange}
                        />
                    </div>
                    <div
                        className={
                            styles.DeploymentCompareRangePickerCardContentRight
                        }
                    >
                        <AppRangePicker
                            onChange={handleRangeChange}
                            value={range}
                        />
                    </div>
                </div>
            </Card>

            <StickyContainer>
                <Tabs
                    tabBarExtraContent={extraTabContent}
                    defaultActiveKey={currentTab}
                    className={styles.DeploymentCompareTabs}
                    size="large"
                    onChange={onTabChange}
                    renderTabBar={renderTabBar}
                    tabBarGutter={16}
                >
                    <TabPane
                        tab="System"
                        key="host"
                        // disabled={deployment?.getDbNodes()?.length === 0}
                    >
                        <HostCompareDashboard
                            uuid={dataStoreUuid}
                            from={rangeFrom}
                            to={rangeTo}
                            interval={refreshInterval}
                            hidden={currentTab !== 'host'}
                            displaySummary={displaySummary}
                            selectedNodes={selectedNodes}
                        />
                    </TabPane>
                    <TabPane tab="Database nodes" key="db">
                        <DbCompareDashboard
                            uuid={dataStoreUuid}
                            from={rangeFrom}
                            to={rangeTo}
                            interval={refreshInterval}
                            hidden={currentTab !== 'db'}
                            deployment={deployment}
                            displaySummary={displaySummary}
                            selectedNodes={selectedNodes}
                        />
                    </TabPane>
                    <TabPane tab="Load balancers" key="lb">
                        <LbCompareDashboard
                            uuid={dataStoreUuid}
                            from={rangeFrom}
                            to={rangeTo}
                            interval={refreshInterval}
                            hidden={currentTab !== 'lb'}
                            deployment={deployment}
                            displaySummary={displaySummary}
                            selectedNodes={selectedNodes}
                        />
                    </TabPane>
                </Tabs>
            </StickyContainer>
        </section>
    );
}

export default withRouter(DeploymentCompare);
