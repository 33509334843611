import DataStoreStatus from './DataStoreStatus';
import Db, { DatabaseType } from './Db';
import FriendlyDateTime from './FriendlyDateTime';
import Vpc, { VpcClassProps } from './Vpc';
import CloudProvider from './CloudProvider';
import DataStoreMaintenanceSettings, {
    DataStoreMaintenanceSettingsClassProps,
} from './DataStoreMaintenanceSettings';
import DbAccount from './DbAccount';
import Helpers from '../services/Helpers';
import DeploymentOptions from './DeploymentOptions';

export type DeploymentsItemClassProps = {
    account_id: string;
    azs: string[] | null;
    cloud_provider: string;
    cluster_id: number;
    cluster_name: string;
    cluster_size: number;
    cluster_status: string;
    cluster_status_text: string;
    cluster_type: string;
    cluster_type_name: string;
    created_at: string;
    database_endpoint: string;
    database_vendor: string;
    database_version: string;
    db_account: any;
    deploy_progress: number;
    disk_size: number;
    effective_disk_size: number;
    disk_type: string;
    high_availability: boolean;
    instance_size: string;
    iops: number | null;
    is_deploying: boolean;
    maintenance_settings: DataStoreMaintenanceSettingsClassProps;
    not_operable_reason: string;
    operable: boolean;
    preferred_region?: string;
    region: string;
    seen_at: string | null;
    ssl_enabled: boolean;
    tags: string[];
    updated_at: string;
    uuid: string;
    vpc: VpcClassProps | null;
    notes?: string;
    cloudProviderFullName: string;
    replica_url?: string;
    primary_url?: string;
    can_upgrade_to?: string;
};

export interface DeploymentsItemInterface {
    accountId: string;
    availabilityZones?: string[];
    cloudProvider: CloudProvider;
    clusterId: number;
    dataStoreName: string;
    numberOfNodes: number;
    clusterStatus: DataStoreStatus;
    clusterType: string;
    clusterTypeName: string;
    createdAt: string;
    deployProgress: number;
    volumeSize: number;
    volumeType: string;
    highAvailability: boolean;
    volumeIops: number;
    instanceSize: string;
    maintenanceSettings: DataStoreMaintenanceSettings;
    notOperableReason: string;
    operable: boolean;
    cloudRegion: string;
    preferredRegion?: string;
    seenAt: string;
    sslEnabled: boolean;
    tags: string[];
    updatedAt: string;
    dataStoreUuid: string;
    db: Db;
    lastSeen: string;
    lastSeenFull: string;
    createdAtFull: string;
    getVpcName: Function;
    getVpcCidr: Function;
    vpc?: Vpc;

    isAws: Function;
    isSafespring: Function;
    isCityCloud: Function;
    isGoogle: Function;
    getName: Function;
    getTruncatedName: Function;
    getNumberOfNodes: Function;
    getDataStoreStatus: Function;
    getDataStoreStatusText: Function;
    getVolumeIops: Function;
    getDeploymentProgressText: Function;
    getMaintenanceDayOfWeek: Function;
    getMaintenanceEndHour: Function;
    getMaintenanceStartHour: Function;
    isProxySql: Function;
    isRedis(): boolean;
    getCloudProvider(): CloudProvider;
    getDbAccount(): DbAccount;
    notes?: string;
    cloudProviderFullName: string;
    replica_url?: string;
    primary_url?: string;
    can_upgrade_to?: string;
}

export default class DeploymentsItem implements DeploymentsItemInterface {
    readonly accountId: string;
    readonly availabilityZones?: string[];
    readonly cloudProvider: CloudProvider;
    readonly clusterId: number;
    readonly dataStoreName: string;
    readonly numberOfNodes: number;
    readonly clusterStatus: DataStoreStatus;
    readonly clusterType: string;
    readonly clusterTypeName: string;
    readonly createdAt: string;
    readonly deployProgress: number;
    readonly volumeSize: number;
    readonly volumeType: string;
    readonly highAvailability: boolean;
    readonly volumeIops: number;
    readonly instanceSize: string;
    readonly maintenanceSettings: DataStoreMaintenanceSettings;
    readonly notOperableReason: string;
    readonly operable: boolean;
    readonly cloudRegion: string;
    readonly seenAt: string;
    readonly sslEnabled: boolean;
    readonly tags: string[];
    readonly updatedAt: string;
    readonly dataStoreUuid: string;
    readonly db: Db;
    readonly dbAccount: DbAccount;
    readonly lastSeen: string;
    readonly lastSeenFull: string;
    readonly createdAtFull: string;
    readonly vpc?: Vpc;
    readonly notes?: string;
    readonly cloudProviderFullName: string;
    readonly preferredRegion?: string;
    readonly replica_url?: string;
    readonly primary_url?: string;
    readonly can_upgrade_to?: string;

    constructor(
        props: DeploymentsItemClassProps,
        deploymentOptions: DeploymentOptions | undefined
    ) {
        this.accountId = props.account_id;
        this.availabilityZones = props.azs ? props.azs : undefined;
        this.cloudProvider = new CloudProvider({
            code: props.cloud_provider,
            name: deploymentOptions?.getFullName(props.cloud_provider),
            regions: [],
            logo: deploymentOptions?.getCloudProviderLogo(props.cloud_provider),
        });
        this.dataStoreName = props.cluster_name;
        this.numberOfNodes = props.cluster_size;
        this.clusterStatus = new DataStoreStatus({
            cluster_status: props.cluster_status,
            cluster_status_text: props.cluster_status_text,
        });
        this.clusterType = props.cluster_type;
        this.notes = props.notes ? props.notes : '';
        this.clusterTypeName = props.cluster_type_name;
        this.clusterId = props.cluster_id;
        this.deployProgress = props.deploy_progress;
        this.volumeSize = props.effective_disk_size
            ? props.effective_disk_size
            : props.disk_size;
        this.volumeType = props.disk_type;
        this.highAvailability = props.high_availability;
        this.instanceSize = props.instance_size;
        this.maintenanceSettings = new DataStoreMaintenanceSettings(
            props.maintenance_settings
        );
        this.volumeIops = props.iops ? props.iops : 0;
        this.notOperableReason = props.not_operable_reason;
        this.operable = props.operable;
        this.cloudRegion = props.region;
        this.seenAt = props.seen_at || '--';
        this.sslEnabled = props.ssl_enabled;
        this.tags = props.tags;
        this.updatedAt = props.updated_at;
        this.dataStoreUuid = props.uuid;
        this.db = new Db({
            database_vendor: props.database_vendor,
            database_version: props.database_version,
            database_endpoint: props.database_endpoint,
        });
        this.dbAccount = new DbAccount({
            username: props.db_account?.database_username,
            password: props.db_account?.database_password,
            host: props.db_account?.database_host,
            database: props.db_account?.database_database,
            privileges: props.db_account?.database_privileges,
        });
        this.lastSeen = props.seen_at
            ? new FriendlyDateTime({
                  date: props.seen_at,
              }).getFriendlyDate()
            : '--';
        this.lastSeenFull = props.seen_at
            ? new FriendlyDateTime({
                  date: props.seen_at,
              }).getFullDate()
            : '--';
        this.createdAt = new FriendlyDateTime({
            date: props.created_at,
        }).getFriendlyDate();
        this.createdAtFull = new FriendlyDateTime({
            date: props.created_at,
        }).getFullDate();
        this.vpc = props.vpc ? new Vpc(props.vpc) : undefined;
        this.cloudProviderFullName = deploymentOptions?.getFullName(
            props.cloud_provider
        );
        this.preferredRegion = props.preferred_region;
        this.primary_url = props.primary_url;
        this.replica_url = props.replica_url;
        this.can_upgrade_to = props.can_upgrade_to;
    }

    getDbIconName(): string {
        return this.db.getIconName();
    }

    getDbVendorName(): string {
        return this.db.getVendorName();
    }

    getDbVendorVersion(): string {
        return this.db.dbVersion;
    }

    getProviderName(): string {
        return this.cloudProvider.code.toUpperCase();
    }

    getCloudProvider(): CloudProvider {
        return this.cloudProvider;
    }

    getCloudProviderCode(): string {
        return this.getCloudProvider().code;
    }

    getFullCloudProviderName(): string {
        if (this.cloudProviderFullName) return this.cloudProviderFullName;
        return 'Unknown';
    }

    getVolumeIops(): number {
        return this.volumeIops;
    }

    getInstanceSize(): string {
        return this.instanceSize;
    }

    getDbEndpoint(): string {
        return this.db.getDbEndpoint();
    }

    isStatusOk(): boolean {
        return this.clusterStatus.isStatusOk();
    }

    isStatusWarning(): boolean {
        return this.clusterStatus.isStatusWarning();
    }

    isStatusError(): boolean {
        return this.clusterStatus.isStatusError();
    }

    isDeploying(): boolean {
        return this.clusterStatus.isDeploying();
    }

    isDeleting(): boolean {
        return this.clusterStatus.isDeleting();
    }

    isUnreachable(): boolean {
        return this.clusterStatus.isUnreachable();
    }

    isMariaDb(): boolean {
        return this.db.isMariadb();
    }

    isPostgreSql(): boolean {
        return this.db.isPostgresql();
    }

    isPercona(): boolean {
        return this.db.isPercona();
    }

    isRedis(): boolean {
        return this.db.isRedis();
    }

    isMSSQL(): boolean {
        return this.db.isMSSQL();
    }

    getServiceName(): string {
        if (this.isMariaDb()) return DatabaseType.MYSQL;
        if (this.isPercona()) return DatabaseType.MYSQL;
        if (this.isPostgreSql()) return DatabaseType.POSTGRES;
        if (this.isRedis()) return DatabaseType.REDIS;
        if (this.isMSSQL()) return DatabaseType.MSSQL;
        return DatabaseType.UNKNOWN;
    }

    getDeploymentSizeDescription(): string {
        return this.numberOfNodes === 1 ? 'Development' : 'Production';
    }

    getVpcName(): string {
        return this.vpc?.getName() || '---';
    }

    getVpcCidr(): string {
        return this.vpc?.getCidrIpv4Block() || '---';
    }

    isAws(): boolean {
        return this.cloudProvider.isAws();
    }

    isVultr(): boolean {
        return this.cloudProvider.isVultr();
    }

    isLintasarta(): boolean {
        return this.cloudProvider.isLintasarta();
    }

    isElastx(): boolean {
        return this.cloudProvider.isElastx();
    }

    isSyseleven(): boolean {
        return this.cloudProvider.isSyseleven();
    }

    isOpenStack(): boolean {
        return (
            this.isLintasarta() ||
            this.isSafespring() ||
            this.isElastx() ||
            this.isSyseleven()
        );
    }

    isSafespring(): boolean {
        return this.cloudProvider.isSafespring();
    }

    isCityCloud(): boolean {
        return this.cloudProvider.isCityCloud();
    }

    isGoogle(): boolean {
        return this.cloudProvider.isGoogle();
    }

    getName(): string {
        return this.dataStoreName;
    }

    getTruncatedName(): string {
        return this.getName().length > 20
            ? `${this.getName().substring(0, 20)}...`
            : this.getName();
    }

    getUUID(): string {
        return this.dataStoreUuid;
    }

    getNumberOfNodes(): number {
        return this.numberOfNodes;
    }

    getDataStoreStatus(): string {
        if (this.isStatusOk()) return 'Available';
        if (this.isDeleting()) return 'Deleting';
        if (this.isDeploying()) return 'Deploying';
        if (this.isStatusError()) return 'Failed';
        return Helpers.capitalizeFirstLetter(this.clusterStatus.status);
    }

    getDataStoreStatusText(): string {
        return this.isDeploying()
            ? `${this.clusterStatus.statusText} (${this.deployProgress}% completed)`
            : this.clusterStatus.statusText;
    }

    getDeploymentProgressText(): string {
        return `${this.deployProgress}%`;
    }

    getMaintenanceDayOfWeek(): number {
        return this.maintenanceSettings.getDayOfWeek();
    }

    getMaintenanceEndHour(): number {
        return this.maintenanceSettings.getEndHour();
    }

    getMaintenanceStartHour(): number {
        return this.maintenanceSettings.getStartHour();
    }

    isProxySql(): boolean {
        return this.isMariaDb() || this.isPercona();
    }

    getDbAccount(): DbAccount {
        return this.dbAccount;
    }

    isSingleAZ(): boolean {
        if (new Set(this.availabilityZones).size > 1) {
            return false;
        }

        return true;
    }

    getClusterTypeName(): string {
        if (this.clusterTypeName.includes('Master')) {
            return this.clusterTypeName.replace('Master', 'Primary');
        } else {
            return this.clusterTypeName;
        }
    }

    getNotes(): string | undefined {
        return this.notes;
    }

    getClusterType(): string {
        return this.clusterType;
    }

    getPrimaryUrl(): string | undefined {
        return this.primary_url;
    }

    getReplicaUrl(): string | undefined {
        return this.replica_url;
    }

    getPrimaryReplicaUrl(services: any): any[] {
        const urlArr = [];
        if (services?.role === 'slave' || services?.role === 'SECONDARY') {
            urlArr.push(this.replica_url);
        } else {
            urlArr.push(this.primary_url);
        }
        return urlArr;
    }

    getScalingSizes(): number[] {
        switch (this.clusterType.toLowerCase()) {
            case 'galera':
            case 'redis':
                return [1, 3, 5];
            case 'mssql_single':
            case 'mssql_ao_async':
                return [];
        }

        return [1, 2, 3, 4, 5];
    }

    getNewNodesKind(): string {
        switch (this.clusterType.toLowerCase()) {
            case 'galera':
            case 'redis':
                return 'primary';
            case 'mssql_single':
            case 'mssql_ao_async':
                return '';
        }

        return 'replica';
    }

    hasStorage(): boolean {
        return !!this.volumeType;
    }
}
