import React, { ReactElement, useEffect, useState } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import useQueriesPerSecondStats from '../../../core/hooks/useQueriesPerSecondStats';
import DeploymentsItem from '../../../types/DeploymentsItem';
import QueriesPerSecondChart from '../../ccx/charts/QueriesPerSecondChart';
import moment from 'moment';

interface Props extends CcxComponentProps {
    dataStore?: DeploymentsItem;
    tiny?: boolean;
}

function DataStoreChart({
    dataStore,
    testId = 'DataStoreChart',
    tiny,
}: Props): ReactElement {
    const [from] = useState(moment().subtract(15, 'minutes').toISOString());
    const [to] = useState(moment().toISOString());

    const { stats, refresh, loading } = useQueriesPerSecondStats({
        dataStoreUuid: dataStore?.dataStoreUuid,
        isPostgreSql: dataStore?.isPostgreSql(),
        from,
        to,
    });

    useEffect(() => {
        const AUTO_REFRESH_INTERVAL = 60000;
        const interval = setInterval(async () => {
            refresh && (await refresh());
        }, AUTO_REFRESH_INTERVAL);

        return () => clearInterval(interval);
    }, []);

    return (
        <QueriesPerSecondChart
            data={stats}
            loading={loading}
            isPostgreSql={dataStore?.isPostgreSql()}
            tiny={tiny}
            testId={testId}
        />
    );
}

export default DataStoreChart;
