import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './AppLogo.module.less';
import { AppLogoUrl } from '../../core/CcxEnv';

function AppLogo(): ReactElement {
    // @TODO: Change the hard-coded default link in NavLink

    return (
        <div className={styles.AppLogo}>
            <NavLink
                to="/projects/default/data-stores"
                data-testid="AppLogoLink"
            >
                <img src={AppLogoUrl} alt="Logo" data-testid="AppLogoImage" />
            </NavLink>
        </div>
    );
}

export default AppLogo;
