import CloudInstance, { CloudInstanceClassProps } from './CloudInstance';

export default class LbNode extends CloudInstance {
    readonly adminPort: number;
    readonly listeningPort: number;
    readonly listeningPortRo: number;

    constructor(props: CloudInstanceClassProps) {
        super(props);
        this.adminPort = props.admin_port;
        this.listeningPort = props.listening_port;
        this.listeningPortRo = props.listening_port_ro;
    }

    getHostPort() {
        if (this.isHaProxy()) {
            return `${this.publicIp || this.privateIp}:${this.listeningPort}`;
        } else if (this.isProxySql()) {
            return `${this.publicIp || this.privateIp}:${this.listeningPort}`;
        } else {
            return '';
        }
    }

    getFqdnPort() {
        return `${this.getFqdn(true)}:${this.listeningPort}`;
    }
}
