import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Modal, Row, message, notification } from 'antd';
import CcxComponentProps from '../../../../core/CcxComponent';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import useContentDeployWizard from '../../../../core/hooks/useContentDeployWizard';
import DatastoreScalingStep1 from '../DatastoreScalingStep1';
import ProvisionService from '../../../../services/ProvisionService';
import CcxIconInfoCircleTwoTone from '../../../ccx/icons/CcxIconInfoCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../../../ccx/icons/CcxIconCloseCircleTwoTone';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import PrettyNumber from '../../../ccx/common/PrettyNumber';
import DatastoreScalingInfoCard from '../DatastoreScalingInfoCard';
import { billingDisabled } from '../../../../core/CcxEnv';
import DeploymentOptions from '../../../../types/DeploymentOptions';
import useDataStoreJobs from '../../../../core/hooks/useDataStoreJobs';

interface Props extends CcxComponentProps {
    datastore: DeploymentsItem;
    pendingJobs: any[];
    refresh: () => void;
    clearStoreInterval: () => void;
    startStoreInterval: () => void;
    setIsButtonDisabled: Function;
    isButtonDisabled: boolean;
}

function ExtendNodeStorageModal({
    testId = 'extendNodeStorageModal',
    datastore,
    pendingJobs,
    refresh,
    startStoreInterval,
    clearStoreInterval,
    setIsButtonDisabled,
    isButtonDisabled,
}: Props) {
    const [visible, setVisible] = useState(false);
    const [countryCode, setCountryCode] = useState<string | undefined>(
        undefined
    );
    const [monthlyCost, setMonthlyCost] = useState(0);
    const [storage, setStorage] = useState<number>(datastore?.volumeSize);

    const { refresh: refreshJobs } = useDataStoreJobs({
        dataStoreUuid: datastore?.getUUID(),
    });

    const onClose = () => {
        setVisible(false);
        clearStoreInterval();
    };

    const showModal = () => {
        clearStoreInterval();
        setVisible(true);
    };

    const handleStorage = (e: any) => {
        if (e >= datastore?.volumeSize) {
            setStorage(e);
        }
    };
    const { deploymentOptions } = useContentDeployWizard();

    useEffect(() => {
        if (deploymentOptions && datastore) {
            setCountryCode(
                deploymentOptions
                    ?.getCloudRegions(datastore.getCloudProvider().code)
                    ?.filter((r: any) => r.code === datastore.cloudRegion)[0]
                    ?.countryCode
            );
        }
    }, [datastore, deploymentOptions]);

    const getVolumeTypePrice = (
        deploymentOptions: DeploymentOptions | undefined,
        datastore: DeploymentsItem
    ) => {
        const volumeTypes =
            deploymentOptions?.instance?.volumeTypes[
                datastore?.cloudProvider?.code
            ];

        const volumeTypePrice = volumeTypes?.find(
            (v: any) => v.code === datastore?.volumeType
        )?.price;

        return volumeTypePrice / 100;
    };

    useEffect(() => {
        let instanceMonthlyPrice = 0;
        let instanceNumber = datastore?.numberOfNodes || 0;
        let storageMonthlyPrice =
            getVolumeTypePrice(deploymentOptions, datastore) || 0;
        let numberOfGB = storage || 0;
        let iopsMonthlyPrice = 0;
        let numberOfIOPS = 0;

        let monthlyPriceEstimate =
            instanceMonthlyPrice * instanceNumber +
            storageMonthlyPrice * instanceNumber * numberOfGB +
            iopsMonthlyPrice * instanceNumber * numberOfIOPS;

        setMonthlyCost(parseFloat(monthlyPriceEstimate.toFixed(2)));
    }, [datastore, storage, getVolumeTypePrice]);

    const handleSubmit = async () => {
        if (storage >= datastore?.volumeSize + 10) {
            try {
                await ProvisionService.updateDatastoreStorage({
                    dataStoreUuid: datastore?.getUUID(),
                    newStorage: storage,
                });

                refreshJobs();

                setIsButtonDisabled(true);

                notification.open({
                    message: 'Datastore storage',
                    description: 'Your datastore storage will updated shortly.',
                    icon: <CcxIconInfoCircleTwoTone />,
                });

                setStorage(datastore?.volumeSize);
                onClose();
                startStoreInterval();
                setTimeout(() => {
                    setIsButtonDisabled(false);
                }, 5000);
            } catch (e) {
                notification.open({
                    message: 'Datastore storage',
                    description: `There was an error trying to update the node storage. ${e}`,
                    icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
                });
                return;
            }
        } else {
            message.error(
                'New storage at-least 10 GB greater than current storage'
            );
        }
    };

    const volume_size = useMemo(() => {
        if (deploymentOptions && datastore) {
            const code = datastore.cloudProvider.code;
            const volumeType = datastore.volumeType;

            return deploymentOptions.instance.volume_sizes[code][volumeType];
        }

        return null;
    }, [deploymentOptions, datastore]);

    return (
        <>
            <Button
                disabled={pendingJobs?.length > 0 || isButtonDisabled}
                onClick={showModal}
            >
                Scale storage
            </Button>
            <Form>
                <Modal
                    title={'Extend node storage'}
                    open={visible}
                    data-testid={`${testId}Modal`}
                    width={800}
                    onCancel={onClose}
                    footer={
                        <Row
                            justify={billingDisabled ? 'end' : 'space-between'}
                        >
                            {!billingDisabled && (
                                <Col>
                                    <div className="">
                                        <div>
                                            Estimated total cost{' '}
                                            <InfoIcon
                                                info={
                                                    <span>
                                                        This amount will be
                                                        added to your next
                                                        invoice.
                                                    </span>
                                                }
                                            />{' '}
                                        </div>
                                        <div
                                            style={{
                                                color: '#C41D7F',
                                                fontWeight: 'bold',
                                                display: 'flex',
                                            }}
                                        >
                                            <PrettyNumber
                                                prefix={<>&#36;</>}
                                                value={monthlyCost}
                                                sufix={' / month'}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            )}
                            <Col>
                                <Button
                                    data-testid={`${testId}CancelButton`}
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    data-testid={`${testId}SubmitButton`}
                                    onClick={handleSubmit}
                                    type="primary"
                                    disabled={
                                        storage < datastore?.volumeSize + 10
                                    }
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    }
                >
                    <DatastoreScalingInfoCard
                        dataStore={datastore}
                        setCountryCode={setCountryCode}
                        countryCode={countryCode}
                    />
                    <DatastoreScalingStep1
                        dataStore={datastore}
                        storage={storage}
                        handleStorage={handleStorage}
                        volume_size={volume_size}
                    />
                </Modal>
            </Form>
        </>
    );
}

export default ExtendNodeStorageModal;
