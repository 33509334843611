import styles from './DatastoreScalingStepHeader.module.less';
import { ReactNode } from 'react';

interface DatastoreScalingStepHeaderProps {
    title: string;
    subtitle: string;
    children: ReactNode;
}

export default function DatastoreScalingStepHeader({
    title,
    subtitle,
    children,
}: DatastoreScalingStepHeaderProps) {
    return (
        <div className={styles.DatastoreScalingStepHeader}>
            <p>
                <strong>{title}</strong>
                <p className={styles.DatastoreScalingStepHeaderInfo}>
                    {subtitle}
                </p>
            </p>
            {children}
        </div>
    );
}
