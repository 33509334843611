import { FormInstance } from 'antd/lib/form';
import FormFooter from '../../tmp/FormFooter';
import React from 'react';
import { DrawerProps, Modal } from 'antd';

export type BackupsFormModalProps = DrawerProps & {
    form: FormInstance;
    onCancel?: () => void;
};
export default function BackupsFormModal({
    form,
    onCancel,
    ...rest
}: BackupsFormModalProps) {
    const handleSubmit = async () => {
        try {
            await form.validateFields();
        } finally {
            form.submit();
        }
    };
    return (
        <Modal
            title={'Backup Settings'}
            width={400}
            onCancel={onCancel}
            footer={
                <FormFooter
                    showSubmitButton={true}
                    showCancelButton={true}
                    submitButtonText={'save'}
                    onCancel={onCancel}
                    noDivider={true}
                    submitButtonProps={{ onClick: handleSubmit }}
                />
            }
            {...rest}
        />
    );
}
