import SecretText from '../components/SecretText';
import { normalizeHostPort } from '../core/helpers';

export type RequestProps = {
    target?: string;
    hostPort?: string;
    host?: string;
    port?: string;
    username?: string;
    database?: string;
    password?: string;
    protocol?: string;
    service?: string;
};

export default class DsnService {
    public static getDsn({
        target = 'example',
        hostPort = '{your_host:your_port}',
        host = '{your_host}',
        port = '{your_port}',
        username = '{your_username}',
        database = '{your_database_name}',
        password = '{your_password}',
        protocol = 'tcp',
        service = 'mysql',
    }: RequestProps): string {
        let dsn = '';

        const getPassword = () => {
            return username === 'ccxadmin'
                ? password
                : '{REPLACE_WITH_YOUR_PASSWORD}';
        };

        switch (target) {
            case 'dsn.redis':
                dsn = `${service}://${username}:${getPassword()}@${hostPort}/0`;
                break;
            case 'dsn':
                if (service === 'rediss') {
                    dsn = `${service}://${username}:${getPassword()}@${hostPort}`;
                } else if (service === 'mssql') {
                    dsn = `Data Source=${hostPort},${port};User ID=${username};Password=${getPassword()}`;
                } else if (service === 'postgres') {
                    dsn = `${service}://${username}:${getPassword()}@${normalizeHostPort(
                        hostPort,
                        port
                    )}/${database}`;
                } else {
                    dsn = `${service}://${username}:${getPassword()}@${hostPort}/${database}`;
                }

                break;
            case 'go':
                dsn = `${username}:${getPassword()}@${hostPort})/${database}`;
                break;
            case 'php.pdo':
                dsn = `${service}://${username}:${getPassword()}@${hostPort}/${database}`;
                break;
            case 'php.pear.db':
                dsn = `${service}://${username}:${getPassword()}@${hostPort}/${database}`;
                break;
            case 'example':
            default:
                dsn = `mysql://{your_username}:{your_password}@tcp(database_host:database_port)/{database_name}`;
        }

        return dsn;
    }

    public static getDsnUsageExample({
        target = 'example',
        hostPort = '{your_host:your_port}',
        host = '{your_host}',
        port = '{your_port}',
        username = '{your_username}',
        database = '{your_database_name}',
        password = '{your_password}',
        protocol = 'tcp',
        service = 'mysql',
    }: RequestProps): any {
        let dsnForCopy: string[] = [];
        let dsnForDisplay: any[] = [];

        const getPasswordForDisplay = () => {
            return username === 'ccxadmin' ? (
                <SecretText
                    visibleText={`_CLICK_TO_REVEAL_PASSWORD_`}
                    hiddenText={`${password}`}
                    tooltipText="Click to show / hide password"
                    displayInline
                />
            ) : (
                '{REPLACE_WITH_YOUR_PASSWORD}'
            );
        };

        const getPasswordForCopy = () => {
            return username === 'ccxadmin'
                ? password
                : '{REPLACE_WITH_YOUR_PASSWORD}';
        };

        const getDsnForDisplay = (
            service: string,
            username: string,
            password: string,
            host: string,
            port: string,
            database: string
        ) => {
            return [
                <>
                    {`${service}://${username}:`}
                    {getPasswordForDisplay()}
                    {`@${host}:${port}/${database}`}
                </>,
            ];
        };

        switch (target) {
            case 'ado.net':
                dsnForCopy = [
                    `Server=${host};`,
                    `Port=${port};`,
                    `Database=${database};`,
                    `Uid=${username};`,
                    `Pwd=${getPasswordForCopy()};`,
                    `SslMode=Required;`,
                ];
                dsnForDisplay = [
                    <>
                        Server={host}; <br />
                        Port={port}; <br />
                        Database={database};<br />
                        Uid={username}; <br />
                        Pwd=
                        {getPasswordForDisplay()}
                        ; <br />
                        SslMode=Required;
                    </>,
                ];
                break;
            case 'jdbc':
                dsnForCopy = [
                    `String url = "jdbc:${service}://%s:%s/%s?verifyServerCertificate=true&useSSL=true&requireSSL=true", ${host}, ${port}, ${database}";`,
                    `myDbConn = DriverManager.getConnection(url, ${username}, ${getPasswordForCopy()}";`,
                ];
                dsnForDisplay = [
                    <>
                        String url = "jdbc:{service}
                        ://%s:%s/%s?verifyServerCertificate=true&useSSL=true&requireSSL=true",{' '}
                        {host}, {port}, {database}"; <br />
                        myDbConn = DriverManager.getConnection(url, {
                            username
                        }, {getPasswordForDisplay()}
                        ";
                    </>,
                ];
                break;
            case 'node.js':
                dsnForCopy =
                    service === 'mysql'
                        ? [
                              `var conn = mysql.createConnection({host: ${host}, user: ${username}, password: ${getPasswordForCopy()}, database: ${database}, Port: ${port}, ssl: {ca:fs.readFileSync({ca-cert filename})}});`,
                          ]
                        : [
                              `const pg = require('pg');`,
                              ``,
                              `var conn = pg.Client({host: ${host}, user: ${username}, password: ${getPasswordForCopy()}, database: ${database}, port: ${port}, ssl: true);`,
                          ];

                dsnForDisplay =
                    service === 'mysql'
                        ? [
                              `var conn = mysql.createConnection({host: ${host}, user: ${username}, password: `,
                              getPasswordForDisplay(),
                              `, database: ${database}, Port: ${port}, ssl: {ca:fs.readFileSync({ca-cert filename})}});`,
                          ]
                        : [
                              <>
                                  {`const pg = require('pg');`}
                                  <br />
                                  {``}
                                  <br />
                                  {`var conn = pg.Client({host: ${host}, user: ${username}, password: `}
                                  {getPasswordForDisplay()}
                                  {`, database: ${database}, port: ${port}, ssl: true);`}
                              </>,
                          ];
                break;
            case 'odbc':
                dsnForCopy =
                    service === 'mysql'
                        ? [
                              `DRIVER={MySQL ODBC 5.3 UNICODE Driver};`,
                              `Server=${host};`,
                              `Port=${port};`,
                              `Database=${database};`,
                              `Uid=${username};`,
                              `Pwd=${getPasswordForCopy()};`,
                              `sslca={ca-cert filename};`,
                              `sslverify=1;`,
                              `Option=3;`,
                          ]
                        : [
                              `DRIVER={PostgreSQL};`,
                              `Server=${host};`,
                              `Port=${port};`,
                              `Database=${database};`,
                              `Uid=${username};`,
                              `Pwd=${getPasswordForCopy()};`,
                              `sslca={ca-cert filename};`,
                              `sslverify=1;`,
                              `Option=3;`,
                          ];
                dsnForDisplay =
                    service === 'mysql'
                        ? [
                              <>
                                  {`DRIVER={MySQL ODBC 5.3 UNICODE Driver};`}
                                  <br />
                                  {`Server=${host};`}
                                  <br />
                                  {`Port=${port};`}
                                  <br />
                                  {`Database=${database};`}
                                  <br />
                                  {`Uid=${username};`}
                                  <br />
                                  {`Pwd=`}
                                  {getPasswordForDisplay()}
                                  {`;`}
                                  <br />
                                  {`sslca={ca-cert filename};`}
                                  <br />
                                  {`sslverify=1;`}
                                  <br />
                                  {`Option=3;`}
                                  <br />
                              </>,
                          ]
                        : [
                              <>
                                  {`DRIVER={PostgreSQL};`}
                                  <br />
                                  {`Server=${host};`}
                                  <br />
                                  {`Port=${port};`}
                                  <br />
                                  {`Database=${database};`}
                                  <br />
                                  {`Uid=${username};`}
                                  <br />
                                  {`Pwd=`}
                                  {getPasswordForDisplay()}
                                  {`;`}
                                  <br />
                                  {`sslca={ca-cert filename};`}
                                  <br />
                                  {`sslverify=1;`}
                                  <br />
                                  {`Option=3;`}
                                  <br />
                              </>,
                          ];
                break;
            case 'php':
                dsnForCopy =
                    service === 'mysql'
                        ? [
                              `<?php`,
                              ``,
                              `$con = mysqli_init();`,
                              `mysqli_ssl_set($con, NULL, NULL, {ca-cert filename}, NULL, NULL);`,
                              `mysqli_real_connect($con, ${host}, ${username}, ${getPasswordForCopy()}, ${database}, ${port});`,
                          ]
                        : [
                              `<?php`,
                              ``,
                              `$connection = pg_connect("host='${host}' dbname='${database}' user='${username}' password='${getPasswordForCopy()}'")`,
                              `  or die("Failed to create connection to database: ". pg_last_error());`,
                          ];
                dsnForDisplay =
                    service === 'mysql'
                        ? [
                              <>
                                  {`<?php`}
                                  <br />
                                  {``}
                                  <br />
                                  {`$con = mysqli_init();`}
                                  <br />
                                  {`mysqli_ssl_set($con, NULL, NULL, {ca-cert filename}, NULL, NULL);`}
                                  <br />
                                  {`mysqli_real_connect($con, ${host}, ${username}, `}
                                  {getPasswordForDisplay()}
                                  {`, ${database}, ${port});`}
                                  <br />
                              </>,
                          ]
                        : [
                              <>
                                  {`<?php`}
                                  <br />
                                  {``}
                                  <br />
                                  {`$connection = pg_connect("host='${host}' dbname='${database}' user='${username}' password='`}
                                  {getPasswordForDisplay()}
                                  {`'")`}
                                  <br />
                                  {`  or die("Failed to create connection to database: ". pg_last_error());`}
                              </>,
                          ];
                break;
            case 'php.pdo':
                const dsnPdo = this.getDsn({
                    target,
                    hostPort,
                    host,
                    port,
                    username,
                    database,
                    password,
                    protocol,
                    service,
                });
                dsnForCopy = [
                    `<?php`,
                    ``,
                    `$dsn = '${dsnPdo}`,
                    ``,
                    `$dbh = new PDO($dsn, $username, $password);`,
                ];
                dsnForDisplay = [
                    <>
                        {`<?php`}
                        <br />
                        {``}
                        <br />
                        {`$dsn = '`}
                        {getDsnForDisplay(
                            service,
                            username,
                            password,
                            host,
                            port,
                            database
                        )}
                        {`'`}
                        <br />
                        {``}
                        <br />
                        {`$dbh = new PDO($dsn, $username, $password);`}
                    </>,
                ];
                break;
            case 'php.pear.db':
                const dsnPear = this.getDsn({
                    target,
                    hostPort,
                    host,
                    port,
                    username,
                    database,
                    password,
                    protocol,
                    service,
                });
                dsnForCopy = [
                    `<?php`,
                    ``,
                    `require_once('DB.php');`,
                    ``,
                    `$db = DB::connect('${dsnPear}');`,
                ];
                dsnForDisplay = [
                    <>
                        {`<?php`}
                        <br />
                        {``}
                        <br />
                        {`require_once('DB.php');`}
                        <br />
                        {``}
                        <br />
                        {`$db = DB::connect('`}
                        {getDsnForDisplay(
                            service,
                            username,
                            password,
                            host,
                            port,
                            database
                        )}
                        {`');`}
                    </>,
                ];
                break;
            case 'python':
                dsnForCopy =
                    service === 'mysql'
                        ? [
                              `cnx = ${service}.connector.connect(user=${username}, password=${getPasswordForCopy()}, host=${host}, port=${port}, database=${database}, ssl_ca={ca-cert filename}, ssl_verify_cert=true)`,
                          ]
                        : [
                              `import psycopg2`,
                              ``,
                              `host = "${host}:${port}"`,
                              `dbname = "${database}"`,
                              `user = "${username}"`,
                              `password = "${getPasswordForCopy()}"`,
                              `sslmode = "require"`,
                              ``,
                              `conn_string = "host={0} user={1} dbname={2} password={3} sslmode={4}".format(host, user, dbname, password, sslmode)`,
                              `conn = psycopg2.connect(conn_string)`,
                          ];
                dsnForDisplay =
                    service === 'mysql'
                        ? [
                              <>
                                  {`cnx = ${service}.connector.connect(user=${username}, password=`}
                                  {getPasswordForDisplay()}
                                  {`, host=${host}, port=${port}, database=${database}, ssl_ca={ca-cert filename}, ssl_verify_cert=true)`}
                              </>,
                          ]
                        : [
                              <>
                                  {`import psycopg2`}
                                  <br />
                                  {``}
                                  <br />
                                  {`host = "${host}:${port}"`}
                                  <br />
                                  {`dbname = "${database}"`}
                                  <br />
                                  {`user = "${username}"`}
                                  <br />
                                  {`password = "`}
                                  {getPasswordForDisplay()}
                                  {`"`}
                                  <br />
                                  {`sslmode = "require"`}
                                  <br />
                                  {``}
                                  <br />
                                  {`conn_string = "host={0} user={1} dbname={2} password={3} sslmode={4}".format(host, user, dbname, password, sslmode)`}
                                  <br />
                                  {`conn = psycopg2.connect(conn_string)`}
                              </>,
                          ];
                break;
            case 'ruby':
                dsnForCopy =
                    service === 'mysql'
                        ? [
                              `client = Mysql2::Client.new(username: ${username}, password: ${getPasswordForCopy()}, database: ${database}, host: ${host}, port: ${port}, sslca:{ca-cert filename}, sslverify:false, sslcipher:'AES256-SHA')`,
                          ]
                        : [
                              `require 'pg'`,
                              ``,
                              `begin`,
                              `  connection = PG::Connection.new(:host => '${host}', :user => '${username}', :dbname => '${database}', :port => '${port}', :password => '${getPasswordForCopy()}')`,
                          ];
                dsnForDisplay =
                    service === 'mysql'
                        ? [
                              <>
                                  {`client = Mysql2::Client.new(username: ${username}, password: `}
                                  {getPasswordForDisplay()}
                                  {`, database: ${database}, host: ${host}, port: ${port}, sslca:{ca-cert filename}, sslverify:false, sslcipher:'AES256-SHA')`}
                              </>,
                          ]
                        : [
                              <>
                                  {`require 'pg'`}
                                  <br />
                                  {``}
                                  <br />
                                  {`begin`}
                                  <br />
                                  {`  connection = PG::Connection.new(:host => '${host}', :user => '${username}', :dbname => '${database}', :port => '${port}', :password => '`}
                                  {getPasswordForDisplay()}
                                  {`')`}
                              </>,
                          ];
                break;
            case 'go':
                const dsnGo = this.getDsn({
                    target,
                    hostPort,
                    host,
                    port,
                    username,
                    database,
                    password,
                    protocol,
                    service,
                });
                dsnForCopy =
                    service !== 'mysql'
                        ? [
                              `import (`,
                              `  "database/sql"`,
                              `  _ "github.com/go-sql-driver/mysql"`,
                              `)`,
                              ``,
                              `db, err := sql.Open("${service}", "${dsnGo}")`,
                          ]
                        : [
                              `import (`,
                              `  "database/sql"`,
                              `  "fmt"`,
                              `  _ "github.com/lib/pq"`,
                              `)`,
                              ``,
                              `	var connectionString string = fmt.Sprintf("host=%s user=%s password=%s dbname=%s sslmode=require", "${host}", "${username}", "${getPasswordForCopy()}", "${database}")`,
                              ``,
                              `db, err := sql.Open("postgres", "${dsnGo}")`,
                          ];
                dsnForDisplay =
                    service !== 'mysql'
                        ? [
                              <>
                                  {`import (`}
                                  <br />
                                  {` `}
                                  {`  "database/sql"`}
                                  <br />
                                  {`  _ "github.com/go-sql-driver/mysql"`}
                                  <br />
                                  {`)`}
                                  <br />
                                  {``}
                                  <br />
                                  {`db, err := sql.Open("${service}", "`}
                                  {`${username}:`}
                                  {getPasswordForDisplay()}
                                  {`@${host}:${port})/${database}`}
                                  {`")`}
                              </>,
                          ]
                        : [
                              <>
                                  {`import (`}
                                  <br />
                                  {`  "database/sql"`}
                                  <br />
                                  {`  "fmt"`}
                                  <br />
                                  {`  _ "github.com/lib/pq"`}
                                  <br />
                                  {`)`}
                                  <br />
                                  {``}
                                  <br />
                                  {`	var connectionString string = fmt.Sprintf("host=%s user=%s password=%s dbname=%s sslmode=require", "${host}", "${username}", "${getPasswordForCopy()}", "${database}")`}
                                  <br />
                                  {``}
                                  <br />
                                  {`db, err := sql.Open("postgres", "`}
                                  {`${username}:`}
                                  {getPasswordForDisplay()}
                                  {`@${host}:${port})/${database}`}
                                  {`")`}
                              </>,
                          ];
                break;
            case 'dsn':
                dsnForCopy = [
                    this.getDsn({
                        target,
                        hostPort,
                        host,
                        port,
                        username,
                        database,
                        password,
                        protocol,
                        service,
                    }),
                ];
                dsnForDisplay = getDsnForDisplay(
                    service,
                    username,
                    password,
                    host,
                    port,
                    database
                );
                break;
        }

        return { dsnForCopy, dsnForDisplay };
    }
}
